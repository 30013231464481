"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EMessagesResponsesChat = void 0;
var EMessagesResponsesChat;
(function (EMessagesResponsesChat) {
    EMessagesResponsesChat["CONNECTED_RESPONSE"] = "CONNECTED_RESPONSE";
    EMessagesResponsesChat["MESSAGE_ERROR_RESPONSE"] = "MESSAGE_ERROR_RESPONSE";
    EMessagesResponsesChat["MESSAGE_NOTIFICATION"] = "MESSAGE_NOTIFICATION";
    EMessagesResponsesChat["KYC_NOTIFICATION"] = "KYC_NOTIFICATION";
    EMessagesResponsesChat["NOTIFICATION"] = "NOTIFICATION";
    EMessagesResponsesChat["BROADCAST"] = "BROADCAST";
})(EMessagesResponsesChat || (exports.EMessagesResponsesChat = EMessagesResponsesChat = {}));
