import {CountryModel} from "@prestinly/backend/src/modules/countries/models/country.model";
import {UUID} from "crypto";

export class Country implements CountryModel {
  get flagUrl() {
    return `https://raw.githubusercontent.com/hampusborgos/country-flags/main/svg/${this.iso2Code.toLowerCase()}.svg`;
  }

  id: UUID;
  iso2Code: string;
  iso3Code: string;
  name: string;
  countryCode: string;
  phoneCode: string;

  constructor(data: CountryModel) {
    this.id = data.id;
    this.iso2Code = data.iso2Code;
    this.iso3Code = data.iso3Code;
    this.name = data.name;
    this.countryCode = data.countryCode;
    this.phoneCode = data.phoneCode;
  }

  toJson() {
    return {
      'id': this.id,
      'iso2Code': this.iso2Code,
      'iso3Code': this.iso3Code,
      'name': this.name,
      'countryCode': this.countryCode,
      'phoneCode': this.phoneCode,
    }
  }

}
