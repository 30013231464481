import {Injectable} from '@angular/core';
import {ApiService} from "../api/api.service";
import {LazyData} from "../model/data";
import {User} from "../model/user";
import {OnboardingData, OnboardingSendData} from "../model/onboarding";
import {StorageDataKey, StorageService} from "../service/storage.service";
import {BehaviorSubject} from 'rxjs';
import {UserModelData} from "@prestinly/core-lib/lib/types/users";
import Smartlook from "smartlook-client";
import {environment} from "../../environments/environment";

@Injectable({providedIn: 'root'})
export class UserRepositoryService {

  public meSubject = new BehaviorSubject<User | null>(null);
  meSubject$ = this.meSubject.asObservable();

  get isLoggedIn() {
    return this.meSubject.value != null;
  }

  constructor(
    private api: ApiService,
    private storage: StorageService,
    ) {}

  meLazy(): LazyData<User> | null {
    let memoryData = new User(this.storage.getLocalJsonData(StorageDataKey.apiUser) as UserModelData);
    return {
      cache: memoryData,
      future: () => this.me(),
    };
  }

  async me(): Promise<User> {
    return this.api.getCurrentUser().then((data: UserModelData) => {
      this.meSubject.next(new User(data));
      if (environment.isProd) {
        Smartlook.identify(data.id, {
          email: data.email,
          isCreator: data.isCreator,
          nickname: data.nickname,
        });
      }
      if (data.onboardingData != null && data.kycVerified && !data.isCreator) {
        return this.completeOnboarding().then(_ => {
          return this.me();
        });
      }
      return new User(data);
    });
  }

  async sendOnboardingForm(data: OnboardingSendData): Promise<OnboardingData> {
    return this.api.sendOnboardingForm(data).then(result => {
      return new OnboardingData(result);
    });
  }

  async completeOnboarding(): Promise<any> {
    await this.api.completeOnboarding();
  }

  async getKycUrl(): Promise<string> {
    return this.api.getKycUrl().then(url => {
      return url;
    });
  }
}
