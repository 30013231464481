import {UserImageModelData, UserModelData} from "@prestinly/core-lib/lib/types/users";
import {CreatorDetailsModelData} from "@prestinly/core-lib/lib/types/creator-details";
import {OnboardingCreatorModelData} from "@prestinly/core-lib/lib/types/onboarding";
import {UUID} from "crypto";
import {EUserImageType} from "@prestinly/core-lib/lib/enums/user-image-type.enum";
import { EAuthProvider } from "@prestinly/core-lib/lib/enums/auth-provider.enum";

export class User implements UserModelData {
  constructor(private data: UserModelData) {
    if(data == null) {
      return;
    }
    this.aNetProfileId = data.aNetProfileId;
    this.countFollowers = data.countFollowers;
    this.countFollows = data.countFollows;
    this.countPosts = data.countPosts;
    this.creatorDetails = data.creatorDetails;
    this.deletedAt = data.deletedAt;
    this.email = data.email;
    this.emailConfirmed = data.emailConfirmed;
    this.id = data.id;
    this.images = data.images;
    this.isCreator = data.isCreator;
    this.kycVerified = data.kycVerified;
    this.nickname = data.nickname;
    this.onboardingData = data.onboardingData;
    this.referralCode = data.referralCode;
    this.hasPassword = data.hasPassword;
    this.provider = data.provider;
  }

  get social() {
    return {
      facebook: this.creatorDetails?.otherSocial,
      twitter: this.creatorDetails?.twitter,
      instagram: this.creatorDetails?.instagram,
    }
  }

  get avatarUrl(): string | null {
    return this.images.find((data) => data.imageType == EUserImageType.AVATAR)?.url ?? null;
  }

  get backgroundUrl(): string | null {
    return this.images
    .find((data) => data.imageType == EUserImageType.BACKGROUND)?.url ?? null;
  }

  toJson() {
    return {
      'aNetProfileId': this.aNetProfileId,
      'countFollowers': this.countFollowers,
      'countFollows': this.countFollows,
      'countPosts': this.countPosts,
      'creatorDetails': this.creatorDetails,
      'deletedAt': this.deletedAt,
      'email': this.email,
      'emailConfirmed': this.emailConfirmed,
      'id': this.id.toString(),
      'images': this.images,
      'isCreator': this.isCreator,
      'kycVerified': this.kycVerified,
      'nickname': this.nickname,
      'onboardingData': this.onboardingData,
      'referralCode': this.referralCode,
      'hasPassword': this.hasPassword,
    }
  }

  aNetProfileId: string | null;
  countFollowers: number;
  countFollows: number;
  countPosts: number;
  creatorDetails: CreatorDetailsModelData | null;
  deletedAt: Date | null;
  email: string;
  emailConfirmed: boolean;
  id: UUID;
  images: UserImageModelData[];
  isCreator: boolean;
  kycVerified: boolean;
  nickname: string;
  onboardingData: OnboardingCreatorModelData | null;
  referralCode: string;
  hasPassword: boolean;
  provider: EAuthProvider;
}
