import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {IHubFileResponse} from "@prestinly/core-lib/lib/types/hub/creator/file.creator.hub.interface";

@Injectable({
  providedIn: 'root'
})
export class MediaPreviewService {
  public mediaPreview = new BehaviorSubject<IHubFileResponse | null>(null);
  public mediaUrlPreview = new BehaviorSubject<string | null>(null);
  constructor() {}
  showFile(file: IHubFileResponse) {
    this.mediaPreview.next(file);
  }
  showImageFileUrl(url: string) {
    this.mediaUrlPreview.next(url);
  }
  closeFile() {
    this.mediaPreview.next(null);
    this.mediaUrlPreview.next(null);
  }
}
