"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EMessagesEventsChat = void 0;
var EMessagesEventsChat;
(function (EMessagesEventsChat) {
    EMessagesEventsChat["LIST_MESSAGES"] = "LIST_MESSAGES";
    EMessagesEventsChat["LIST_DELAYED_MESSAGES"] = "LIST_DELAYED_MESSAGES";
    EMessagesEventsChat["LIST_BROADCAST_MESSAGES"] = "LIST_BROADCAST_MESSAGES";
    EMessagesEventsChat["SEND_BROADCAST_MESSAGES"] = "SEND_BROADCAST_MESSAGES";
    EMessagesEventsChat["SEND_AUDIOS"] = "SEND_AUDIOS";
    EMessagesEventsChat["SEND_DOCUMENTS"] = "SEND_DOCUMENTS";
    EMessagesEventsChat["SEND_TEXT_MESSAGE"] = "SEND_TEXT_MESSAGE";
    EMessagesEventsChat["SEND_VIDEOS"] = "SEND_VIDEOS";
    EMessagesEventsChat["SEND_PHOTOS"] = "SEND_PHOTOS";
    EMessagesEventsChat["SEND_VOICE"] = "SEND_VOICE";
    EMessagesEventsChat["SEND_MULTIPART"] = "SEND_MULTIPART";
    EMessagesEventsChat["UPDATE_MESSAGE"] = "UPDATE_MESSAGE";
    EMessagesEventsChat["UPDATE_DELAY_MESSAGE"] = "UPDATE_DELAY_MESSAGE";
    EMessagesEventsChat["UPDATE_BROADCAST_MESSAGES"] = "UPDATE_BROADCAST_MESSAGES";
    EMessagesEventsChat["SET_VIEWED_MESSAGES"] = "SET_VIEWED_MESSAGES";
    EMessagesEventsChat["TYPING"] = "TYPING";
    EMessagesEventsChat["ADD_REACTION"] = "ADD_REACTION";
    EMessagesEventsChat["REMOVE_REACTION"] = "REMOVE_REACTION";
    EMessagesEventsChat["HIDE_MESSAGE"] = "HIDE_MESSAGE";
    EMessagesEventsChat["HIDE_DELAY_MESSAGE"] = "HIDE_DELAY_MESSAGE";
    EMessagesEventsChat["HIDE_BROADCAST_MESSAGE"] = "HIDE_BROADCAST_MESSAGE";
    EMessagesEventsChat["CREATE_PRIVATE_CHAT"] = "CREATE_PRIVATE_CHAT";
    EMessagesEventsChat["CREATE_GROUP_CHAT"] = "CREATE_GROUP_CHAT";
    EMessagesEventsChat["IS_CHAT_EXISTS"] = "IS_CHAT_EXISTS";
    EMessagesEventsChat["UNREAD"] = "UNREAD";
    EMessagesEventsChat["LIST_CHATS"] = "LIST_CHATS";
    EMessagesEventsChat["LIST_BLOCKED_CHATS"] = "LIST_BLOCKED_CHATS";
    EMessagesEventsChat["LIST_BROADCAST_CHATS"] = "LIST_BROADCAST_CHATS";
    EMessagesEventsChat["HIDE_CHAT"] = "HIDE_CHAT";
    EMessagesEventsChat["BLOCK_CHAT"] = "BLOCK_CHAT";
    EMessagesEventsChat["UNBLOCK_CHAT"] = "UNBLOCK_CHAT";
})(EMessagesEventsChat || (exports.EMessagesEventsChat = EMessagesEventsChat = {}));
