export class GetChatListParams {
    page = 1;
    limit = 16;
    toDate = new Date().toISOString();
}

export class GetMessageListParams {
    chatId = '';
    page = 1;
    limit = 50;

    constructor(chatId?: string, page?: number) {
        this.chatId = chatId || '';
        this.page = page || 1;
    }
}

export class GetDelayedMessageListParams {
    chatId = '';
    page = 1;
    limit = 50;

    constructor(chatId?: string, page?: number) {
        this.chatId = chatId || '';
        this.page = page || 1;
    }
}
