"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EMessagesMessageNotificationChat = void 0;
var EMessagesMessageNotificationChat;
(function (EMessagesMessageNotificationChat) {
    EMessagesMessageNotificationChat["NEW_TEXT_MESSAGE"] = "NEW_TEXT_MESSAGE";
    EMessagesMessageNotificationChat["NEW_AUDIO_MESSAGE"] = "NEW_AUDIO_MESSAGE";
    EMessagesMessageNotificationChat["NEW_VIDEO_MESSAGE"] = "NEW_VIDEO_MESSAGE";
    EMessagesMessageNotificationChat["NEW_PHOTO_MESSAGE"] = "NEW_PHOTO_MESSAGE";
    EMessagesMessageNotificationChat["NEW_DOCUMENT_MESSAGE"] = "NEW_DOCUMENT_MESSAGE";
    EMessagesMessageNotificationChat["NEW_VOICE_MESSAGE"] = "NEW_VOICE_MESSAGE";
    EMessagesMessageNotificationChat["NEW_MULTIPART_MESSAGE"] = "NEW_MULTIPART_MESSAGE";
    EMessagesMessageNotificationChat["NEW_DELAY_TEXT_MESSAGE"] = "NEW_DELAY_TEXT_MESSAGE";
    EMessagesMessageNotificationChat["NEW_DELAY_AUDIO_MESSAGE"] = "NEW_DELAY_AUDIO_MESSAGE";
    EMessagesMessageNotificationChat["NEW_DELAY_VIDEO_MESSAGE"] = "NEW_DELAY_VIDEO_MESSAGE";
    EMessagesMessageNotificationChat["NEW_DELAY_PHOTO_MESSAGE"] = "NEW_DELAY_PHOTO_MESSAGE";
    EMessagesMessageNotificationChat["NEW_DELAY_DOCUMENT_MESSAGE"] = "NEW_DELAY_DOCUMENT_MESSAGE";
    EMessagesMessageNotificationChat["NEW_DELAY_VOICE_MESSAGE"] = "NEW_DELAY_VOICE_MESSAGE";
    EMessagesMessageNotificationChat["NEW_DELAY_MULTIPART_MESSAGE"] = "NEW_DELAY_MULTIPART_MESSAGE";
    EMessagesMessageNotificationChat["NEW_REACTION"] = "NEW_REACTION";
    EMessagesMessageNotificationChat["REMOVED_REACTION"] = "REMOVED_REACTION";
    EMessagesMessageNotificationChat["TYPING"] = "TYPING";
    EMessagesMessageNotificationChat["MESSAGES_READ_BY_USER"] = "MESSAGES_READ_BY_USER";
    EMessagesMessageNotificationChat["MESSAGE_UPDATED"] = "MESSAGE_UPDATED";
    EMessagesMessageNotificationChat["DELAY_MESSAGE_UPDATED"] = "DELAY_MESSAGE_UPDATED";
    EMessagesMessageNotificationChat["DELAY_MESSAGE_HIDDEN"] = "DELAY_MESSAGE_HIDDEN";
    EMessagesMessageNotificationChat["MESSAGE_HIDDEN"] = "MESSAGE_HIDDEN";
    EMessagesMessageNotificationChat["NEW_CHAT"] = "NEW_CHAT";
    EMessagesMessageNotificationChat["NEW_BROADCAST"] = "NEW_BROADCAST";
    EMessagesMessageNotificationChat["CHAT_UPDATED"] = "CHAT_UPDATED";
    EMessagesMessageNotificationChat["BROADCAST_UPDATED"] = "BROADCAST_UPDATED";
    EMessagesMessageNotificationChat["KYC_APPROVED"] = "KYC_APPROVED";
    EMessagesMessageNotificationChat["KYC_DECLINED"] = "KYC_DECLINED";
    EMessagesMessageNotificationChat["KYC_EXPIRED"] = "KYC_EXPIRED";
    EMessagesMessageNotificationChat["NOTIFICATION"] = "NOTIFICATION";
})(EMessagesMessageNotificationChat || (exports.EMessagesMessageNotificationChat = EMessagesMessageNotificationChat = {}));
